import { ready } from '#js/components/utils'

export default function setupImageFieldListener () {
  const imageUpload = document.getElementById('id_image')
  const placeholder = document.getElementById('image-placeholder')
  const image = document.getElementById('image-output')
  if (imageUpload && image) {
    imageUpload.addEventListener(
      'input',
      function (event) {
        image.src = window.URL.createObjectURL(event.target.files[0])
        image.style.display = 'block'
        if (placeholder) {
          placeholder.style.display = 'none'
        }
      })

    const clearInput = document.getElementById('image-clear_id')
    if (clearInput) {
      clearInput.addEventListener('change', function () {
        if (this.checked) {
          image.style.opacity = '0.2'
        } else {
          image.style.opacity = '1'
        }
      })
    }
  }
}

ready(function () {
  setupImageFieldListener()
})
